.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #f0f4f8;
  font-weight: 600;
}

.main-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 100px;
}
.headerContainer{
    width: 100%;
    height: 60px;
    background-color: #5730FB;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 30px;
    padding-right: 30px;
}

.logout{
    width: 100px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 8px;
    background-color: #FF9900;
    color: #fff;
    cursor: pointer;
}

.logout .icon{
    margin-left: 5px;
}

h3{
    color: #fff;
}

h3 span{
    color: #FF9900;
}

p{
    color: #fff;
}